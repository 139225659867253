.knobset {
  display: flex;

  label {
    margin: 1em;
    font-size: 12px;
    text-align: center;
    text-transform: capitalize;
  }
}
